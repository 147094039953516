<template>
  <div id="Lucky_open">
    <div class="Lucky_zzc" v-show="adds">
      <div class="Lucky_lucky">
        <div class="Lucky_little">
          <lucky @onEmitIndex="onEmitIndex" @idBox="idBox"></lucky>
        </div>
      </div>
    </div>
    <div class="Title">
      <p>{{ currentIndex.name }}</p>
    </div>

    <div class="Lucky_opens">
      <div class="bg_bai">{{ NumIdx }}%</div>
      <div>
        <div class="position-absolute" style="margin-top: -25%;margin-left:-42px; z-index: 99">
          <linear-gradient :unconfirmedValue="NumIdx / 100"></linear-gradient>
        </div>
        <!-- {{console.log(this.NumIdx)}} -->
        <CircleProgress
          :percentNum="NumIdx"
          speed="10"
          size="180"
          color="#7581EB"
          backgroundColor="#fff"
          content="平均送达率"
          icon="mail"
          class="cir"
        />
        <img
          src="https://static.d2topbox.com/images/upgrade/bg-circle.png"
          alt=""
          class="bg-circle"
        />

        <div ref="turntable" class="fuc_box turntable" alt="turntable">
          <img src="../../assets/images/Lucky/fuc.png" alt="" class="fuc" />
        </div>
        <img
          :src="currentIndex.cover"
          alt=""
          @click="add()"
          class="bg-circless"
          v-if="currentIndex.cover"
        />
        <img
          src="../../assets/images/OpenBox/fl.png"
          alt=""
          class="bg-circles"
          v-else
        />
        <Dynamic
          :DetailList="DetailList"
          :Awardlist="AwardArr"
          :Dynamic_checked="Dynamic_checked"
          :Dynamic_num="Dynamic_num"
          @setAnimation="setAnimation"
          @setEquipment="setEquipment"
          v-show="AnimationShow == '1'"
        ></Dynamic>
        <!-- <div style="color: #fff; text-align: center">
			{{ currentIndex.dura_alias }}
		  </div> -->
      </div>
    </div>
    <div class="box_proportion">
      <!-- <van-slider v-model="NumIdx" @change="onChange" :min="5" :max="95"/> -->

      <div class="proportion_item min">
        <div class="shadow"></div>
        {{ NumIdx }}%
      </div>
      <div class="proportion_out">
        <van-slider v-model="NumIdx" @change="onChange" :min="1" :max="75">
          <template #button>
            <div class="custom-button">{{ NumIdx }}</div>
          </template>
        </van-slider>
      </div>

      <div class="proportion_item max">
        <div class="shadow"></div>
        {{ 100 - NumIdx }}%
      </div>
    </div>
    <div class="price_box" v-show="AnimationShow == '0'">
      <img class="mr-1" src="@/assets/images/public/Gold.png" alt="" />
      <span v-if="currentIndex.bean">
        {{ (currentIndex.bean * (NumIdx / 100)).toFixed(2) }}
      </span>
      <span v-else> 0.00 </span>
    </div>
    <div class="open_btn" v-show="AnimationShow == '0'">
      <van-button type="info" size="small" @click="PostOpen" :disabled="flags"
        >开启箱子</van-button
      >
      <!-- <div class="Animation_switch">
			<p>跳过动画</p>
			<van-switch v-model="Animation_checked" size="24px" />
		  </div> -->
    </div>
    <Equipment
      v-if="false"
      :Dynamic_num="Dynamic_num"
      :EquipmentItem="EquipmentItem"
      v-show="EquipmentShow == '1'"
      :key="EquipmentShow"
    ></Equipment>
    <Show
      v-show="WinningShow"
      :WinningShow="WinningShow"
      @setShow="setShow"
      :Awardlist="Awardlist"
    ></Show>
    <div class="bottoms">
      <div class="bottom_left" @click="add()">
        <p>
          <img
            src="../../assets/images/Lucky/10003.png"
            alt=""
            @click="add()"
          />升级目标
        </p>
        <div class="add" @click="add()">
          <div v-if="currentIndex.cover" @click="add()">
            <img :src="currentIndex.cover" alt="" @click="add()" />
          </div>
          <div v-else>
            <img
              src="../../assets/images/Lucky/10002.png"
              alt=""
              @click="add()"
            />
          </div>
        </div>
        <p>请选择升级目标</p>
      </div>
      <div class="bottom_right">
        <p><img src="../../assets/images/Lucky/10004.png" alt="" />保底饰品</p>
        <div>
          <img src="../../assets/images/985/roll/di.webp" alt="" style="width:150px" />
        </div>
      </div>
    </div>
  </div>
</template>
	
	<script>
import Details from "@/components/PubOpen/Details.vue";
import Dynamic from "@/components/PubOpen/Dynamic.vue";
import Equipment from "@/components/PubOpen/Equipment.vue";
import Show from "@/components/PubOpen/Show.vue";
import { AwardGet, LuckyOpen, PersonalInfo } from "@/network/api.js";
import { mapMutations } from "vuex";
import CircleProgress from "./CircleProgress.vue";
import transforn from "./transforn.vue";
import throttle from "./throttle.js";
import Lucky from "./index.vue";
import linearGradient from "./linearGradient";
// import message from 'element-ui'
export default {
  name: "Open",
  data() {
    return {
      arr: [],
      DetailData: {}, //箱子信息
      NumIdx: 1, //箱子比列
      DetailList: [], //箱内物品
      Animation_checked: false, //动画开关
      WinningShow: false, //奖品遮罩层
      Awardlist: [], //获得奖品数据
      AwardArr: [], //获得奖品数据(操作后)
      Dynamic_checked: "0", //播放动画('0':不播放,'1':播放)
      Dynamic_num: 0, //奖品数量
      AnimationShow: "0", //动画显示隐藏'0':不显示,'1':显示)
      EquipmentItem: {}, //下方奖品栏子级
      EquipmentShow: "0", //下方奖品栏显示
      isGo: false, //是否正在执行动画
      oTurntable: "", //执行动画的对象
      randomDeg: 0, //即将旋转的度数
      lastDeg: 0, //上一次旋转的度数
      goTimes: 1000, //抽奖次数
      adds: false,
      currentIndex: "",
      userBean: "",
      flags: false,
    };
  },

  //给页面添加监听事件，如果存在，那再执行；
  mounted() {},

  created() {
    this.GetPersonalInfo();
    if (this.currentIndex == "") {
    } else {
      this.GetAwardGet();
    }
    this.$bus.$on("detile", () => {
      this.detile();
    });
  },
  beforeDestroy() {
    this.$bus.$off("detile");
  },
  mounted() {
    this.GetPersonalInfo();
    this.oTurntable = this.$refs.turntable;
    window.eventBus.$on("LuckyBox", () => {
      // 不带参数
      this.idBox();
    });
    this.detile();
  },
  methods: {
    idBox() {
      this.adds = false;
    },
    detile() {
      this.adds = false;
    },
    onEmitIndex(itemx) {
      this.currentIndex = itemx;
      // console.log(itemx);
    },
    //遮罩层显示
    add() {
      this.adds = true;
    },
    //宝箱详情
    GetAwardGet() {
      AwardGet(this.currentIndex.id).then((res) => {
        // console.log(res.data.data)
        this.currentIndex = res.data.data[0];
        let Arr = [];
        Arr.push(res.data.data[0], {
          cover: require("@/assets/images/Lucky/cheap.png"),
        });
        this.DetailList = Arr.concat(Arr);
      });
    },

    //开箱
    PostOpen() {
      this.flags = true;
      // 执行耗时间的代码或事件请求等....

      // 等待事件执行完或者相应的间隔时间后，恢复按钮为可点击状态
      setTimeout(() => {
        this.flags = false;
      }, 5500);
      this.GetPersonalInfo();
      if (this.currentIndex.cover) {
        if (
          this.userBean * 1 >=
          this.currentIndex.bean * (this.NumIdx / 100).toFixed(2)
        ) {
          if (this.NumIdx >= 5) {
            if (localStorage.getItem("token")) {
              if (!this.isGo && this.goTimes > 0) {
                this.getNumber(),
                  setTimeout(() => {
                    // this.zhuan.play();
                  }, 1200);
              } else if (!this.isGo && this.goTimes <= 0) {
                alert("抱歉您的抽奖次数用完了");
              } else return; //表明正在抽奖，未结束扔点击无效
            } else {
              this.$toast("请登录");
            }

            LuckyOpen(this.currentIndex.id, this.NumIdx / 100).then((res) => {
              // console.log(this.NumIdx/100)
              // console.log(res.data.data.awardlist)
              this.GetPersonalInfo();
              this.Awardlist = res.data.data.awardlist;
              // if(res.data.data.awardlist)
              // console.log(res.data.data.awardlist[0].name);
              this.arr = res.data.data.awardlist[0].name;
              if (res.data.data.awardlist[0].name == this.currentIndex.name) {
                this.AwardArr = res.data.data.awardlist;
              } else {
                this.AwardArr = [
                  { cover: require("@/assets/images/Lucky/cheap.png") },
                ];
              }
              setTimeout(() => {
                if (this.Animation_checked == false) {
                  this.WinningShow = true;
                } else {
                  this.AnimationShow = "1";
                  this.EquipmentShow = "1";
                  // console.log('false')
                  setTimeout(() => {
                    this.Dynamic_checked = "1";
                    this.Dynamic_num = this.Awardlist.length;
                  }, 100);
                }
              }, 5000);
            });
          } else {
            this.$toast("请大于5%");
          }
        } else {
          this.$toast("c币不足");
        }
      } else {
        this.$toast("请选择饰品后开启");
      }
    },
    getRandom(n, m) {
      //该方法能产生[n,m]之间随机数
      let result = Math.floor(Math.floor(Math.random() * (m - n + 1) + n));
      return result;
    },
    getNumber() {
      let number = this.getRandom(2, this.NumIdx - 2);
      let numbers = this.getRandom(this.NumIdx + 5, 95);
      let caseNum = "";
      setTimeout(() => {
        //console.log(this.arr,this.currentIndex.name)
        if (this.arr == this.currentIndex.name) {
          caseNum = 0;
        } else {
          caseNum = this.getRandom(1, 6);
        }
        switch (caseNum) {
          case 0:
            console.log(number);
            this.ratating(number * 3.6);
            break;
          case 1:
            console.log(numbers);
            this.ratating(numbers * 3.6);
            break;
          case 2:
            console.log(numbers);
            this.ratating(numbers * 3.6);
            break;
          case 3:
            console.log(numbers);
            this.ratating(numbers * 3.6);
            break;
          case 4:
            console.log(numbers);
            this.ratating(numbers * 3.6);
            break;
          case 5:
            console.log(numbers);
            this.ratating(numbers * 3.6);
            break;
          default:
            console.log(numbers);
            this.ratating(numbers * 3.6);
            break;
        }
      }, 1000);
    },
    ratating(deg, text) {
      this.goTimes--;
      this.isGo = true;
      let times = this.getRandom(3, 6); //圈数(从用户体验角度考虑，设计随机转3-6圈。最少3圈，最多6圈)

      this.randomDeg = deg + 360 * times; //记录这次要旋转的度数(传来的度数+圈数)
      let realDeg = 360 - (this.lastDeg % 360) + this.lastDeg + this.randomDeg;
      /*上次指针离初始状态的度数 + 上次的度数 + 这次要旋转的度数
		  (这样的目的是为了每次旋转都从原点开始，保证数据准确)*/
      this.oTurntable.style.transform = `rotate(${realDeg}deg)`;
      setTimeout(() => {
        this.isGo = false;

        this.lastDeg = realDeg; //把这次度数存储起来，方便下一次获取
      }, 4000); //4000ms为css里面写的执行动画的时间
    },
    //宝箱详情
    GetAwardGet() {
      AwardGet(this.currentIndex.id).then((res) => {
        // console.log(res.data.data)
        this.currentIndex = res.data.data[0];
        // console.log(this.currentIndex)
        let Arr = [];
        Arr.push(res.data.data[0], {
          cover: require("@/assets/images/Lucky/cheap.png"),
        });
        this.DetailList = Arr.concat(Arr);
        // console.log(this.DetailList)
      });
    },
    //
    onChange(val) {
      console.log(val);
    },

    //个人信息
    GetPersonalInfo() {
      PersonalInfo().then((res) => {
        this.PostUser(res.data.message);
        this.userBean = res.data.message.bean;
        console.log(this.userBean.bean);
      });
    },

    //接收子级(关闭奖品遮罩层)
    setShow(v) {
      // console.log(v)
      this.WinningShow = v;
      this.EquipmentShow = "0";
    },

    //接收子级(动画结束)
    setAnimation(v) {
      this.WinningShow = v;
      this.AnimationShow = "0";
      this.Dynamic_checked = "0";
    },

    //接收子级(下方奖品栏)
    setEquipment(v) {
      // console.log(v)
      this.EquipmentItem = this.Awardlist[v - 1];
    },

    ...mapMutations(["PostUser"]),
  },

  components: {
    Details,
    Dynamic,
    Equipment,
    Show,
    CircleProgress,
    transforn,
    throttle,
    Lucky,
    linearGradient,
  },
};
</script>
	
	<style lang="scss">
.cir {
  width: auto !important;
  height: auto !important;
}
#Lucky_open {
  .price_box {
    height: 50px;
    color: white;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-size: 20px;
    img {
      width: 20px;
      height: 20px;
    }
  }
  .Lucky_zzc {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    // position: relative;
    .Lucky_lucky {
      position: relative;
      width: 100%;
      height: 100%;
      .Lucky_little {
        width: 390px;
        height: 80%;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        background: rgb(54, 54, 60);
        margin: auto;
        overflow: hidden;
        overflow-y: scroll;
      }
    }
  }
  .bottoms {
    width: 95%;
    height: 160px;
    background: url(../../assets/images/Lucky/10001.png);
    background-size: 100% 100%;
    border-radius: 5px;
    margin: 10px auto;
    display: flex;

    justify-content: center;
    align-items: center;
    .bottom_left {
      justify-content: center;
      flex-wrap: wrap;
      .add {
        img {
          width: 80px;
          height: 80px;
        }
      }
      p:nth-child(3) {
        color: #5f5f71;
        font-size: 16px;
      }
    }
    .bottom_right {
      justify-content: center;
      flex-wrap: wrap;
      div {
        width: 100%;
        justify-content: space-around;
      }
    }
    div {
      width: 50%;
      display: flex;
      p {
        // width: 100%;
        color: #fff;
        display: flex;
        font-size: 18px;
        font-weight: 700;
        img {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
  .Title {
    background-position: center 25px !important;
  }
  .Title > p {
    font-size: 18px;
  }
  .Title::before {
    background: none;
  }
  .Title::after {
    background: none;
  }
  .Lucky_opens {
    display: flex;
    justify-content: center;
    height: 230px;
    position: relative;
	left: 20px;
    .bg_bai {
      position: absolute;
      z-index: 9999;
      left: 4.8rem;
      top: 3.2rem;
      color: rgb(255, 250, 250);
    }
    .percentloop {
      position: absolute;
      top: 1.05rem;
      left: 2.85rem;
      width: 4.3rem;
      height: 4.3rem;
    }
    @keyframes rotate360 {
      100% {
        transform: rotate(360deg);
      }
    }
    .bg-circle {
      animation: rotate360 15s linear infinite;
      position: absolute;
      top: 0;
      width: 6.4rem;
      height: 6.4rem;
      left: 1.8rem;
    }
    .fuc_box {
      position: absolute;
      top: 1.4rem;
      left: 4.75rem;
      height: 130px;
      z-index: 99;
      transition: all 4s;
    }
    .fuc {
      width: 18px;
      height: 90px;
    }
    .bg-circles {
      position: absolute;
      top: -0.2rem;
      left: 1.6rem;
      width: 6.8rem;
      height: 6.8rem;
    }
    .bg-circless {
      position: absolute;
      top: 2rem;
      left: 3.5rem;
      width: 3rem;
      height: 2.5rem;
    }
  }
  .box_proportion {
    width: 100%;
    padding: 0 18px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    border-radius: 6px;
    box-sizing: border-box;
    .proportion_item {
      width: 50px;
      height: 50px;
      background: red;
      text-align: center;
      line-height: 50px;
      font-size: 16px;
      position: relative;
      .shadow {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%);
        transform: translateY(-50%);
      }
      &.min {
        background: url(../../assets/images/Lucky/min.svg) no-repeat center;
        background-size: contain;
        color: rgb(170, 240, 143);
        .shadow {
          box-shadow: rgb(6, 128, 54) 0px 0px 30px 12px;
        }
      }
      &.max {
        background: url(../../assets/images/Lucky/max.svg) no-repeat center;
        background-size: contain;
        color: rgb(240, 179, 143);
        .shadow {
          box-shadow: rgb(240, 179, 143) 0px 0px 30px 12px;
        }
      }
    }
    .proportion_out {
      width: 170px;
      .custom-button {
        width: 34px;
        font-size: 10px;
        line-height: 20px;
        text-align: center;
        background-color: #fff;
        border-radius: 100px;
        color: #1989fa;
      }
    }
  }
  .open_btn:hover {
    cursor: pointer;
  }
  .open_btn {
    text-align: center;
    margin-bottom: 10px;
    .van-button {
      font-size: 14px;
      padding-left: 20px;
      padding-right: 20px;
    }

    .Animation_switch {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 10px;
      margin-top: 10px;
      box-sizing: border-box;
      font-size: 16px;
      color: #fff;

      p {
        margin-right: 5px;
      }
    }
  }
}
</style>
	